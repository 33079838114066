var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.length)?_c('swipe-list',{ref:"list",staticClass:"card mt16 mb20",attrs:{"items":_vm.data,"transition-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"deposit-card-wrapper",on:{"click":function($event){return _vm.itemClick(item)}}},[_c('div',{staticClass:"one-row"},[_c('div',{staticStyle:{}},[_c('div',{class:{
                                'card-price-green':
                                    item.status === 'COMPLETED' ||
                                    item.status === 'COMPLETED_PASS_CREDIT',
                                'card-price-red':
                                    item.status === null ||
                                    item.status !== 'COMPLETED' ||
                                    item.status !== 'COMPLETED_PASS_CREDIT'
                            }},[_vm._v(" "+_vm._s(item.price)+" "+_vm._s(_vm.$t('global.currency'))+" ")])]),_c('div',[_c('div',{staticClass:"card-from-to text-deposit-default"},[_c('div',{staticStyle:{"display":"flex"}},[(item.request)?_c('div',[_c('div',[_vm._v(_vm._s(item.request.from))]),_c('div',[_vm._v(_vm._s(item.request.to))])]):_c('div',[_vm._v(_vm._s(item.description))]),(item.request)?_c('img',{staticClass:"mobile-deposit-icon",staticStyle:{"margin-left":"6px","height":"28px","margin-top":"2px"},attrs:{"src":require("@assets/images/icon-route.svg")}}):_vm._e()]),(item.request)?_c('div',{staticStyle:{"margin-right":"12px"}},[_vm._v(" "+_vm._s(_vm.$formatDate( item.request.created_at, _vm.$i18n.locale ))+" ")]):_vm._e()])])]),_c('div',{staticClass:"one-row"},[_c('div',{staticClass:"card-details"},[_c('Statuses',{attrs:{"item":item}})],1),_c('div',{staticClass:"flex-column-center"},[_c('md-button',{staticClass:"card-call-btn",on:{"click":function($event){return _vm.call(item)}}},[_c('img',{attrs:{"src":require('@assets/images/icon-call.svg'),"alt":"kredyt","title":"kredyt"}}),_c('div',{staticClass:"text uppercase"},[_vm._v(_vm._s(_vm.$t('global.call')))])])],1)])])]}},{key:"left",fn:function(ref){
                            var item = ref.item;
return [(item.request)?_c('div',{staticClass:"swipeout-action swipeout-action-big deposit-action-panel-left"},[_c('div',{on:{"click":function($event){return _vm.itemClick(item)}}},[_c('img',{attrs:{"src":require('@assets/images/icon_search.svg')}}),_vm._v(" "+_vm._s(_vm.$t('deposits.details'))+" ")])]):_vm._e()]}}],null,false,1428965771)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }