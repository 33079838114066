<template>
    <div
        class="mt40 md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
        <div class="one-row  deposits-headline">
            <span class="md-headline deposits-title">{{
                $t('deposits.pageTitle')
            }}</span>
            <div class="one-row">
                <div class="account-balance">
                    <div class="coins">
                        {{ wallet }}
                        {{ $t('global.currency') }}
                    </div>
                    <div class="points">
                        {{ carrierDeposits.points.toFixed(0) }} {{ $t('deposits.points') }}
                    </div>
                </div>
                <md-button
                    class="default-button btn-pay-out"
                    @click="payOffModall"
                >
                    <img
                        :src="require('@assets/images/icon_wallet.svg')"
                        alt="kredyt"
                        title="kredyt"
                    />
                    <div class="text uppercase">{{ $t('deposits.payOut') }}</div>
                </md-button>
            </div>
        </div>

        <div class="right mt16">
            <md-button
                v-for="(item, index) in filters"
                :key="index"
                class="btn-filter"
                :class="{ active: filters[index].status }"
                @click="setFilter(index)"
            >
                <div class="text uppercase">{{ filters[index].info }}</div>
            </md-button>
        </div>
        <mobile v-if="isMobile" :data="filterPaid" />
        <desktop v-else :data="filterPaid" />

        <pay-off-deposit-modal
            :show="showPayOffModal"
            @close="payOffModall"
            :points="carrierDeposits.points"
        />

        <infinite-loading
            v-if="isMounted"
            @infinite="infiniteHandler"
            :identifier="infiniteId"
            infinite-scroll-disabled="busy"
        >
            <div slot="spinner">
                <md-progress-spinner
                    md-mode="indeterminate"
                    :md-stroke="2"
                    :md-diameter="30"
                />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PayOffDepositModal from '../components/modals/payOffDepositModal';
import Mobile from '../components/Deposits/mobile';
import Desktop from '../components/Deposits/desktop';

export default {
    components: {
        PayOffDepositModal,
        Mobile,
        Desktop
    },

    data() {
        return {
            isMounted: false,
            showPayOffModal: false,
            pageCount: 0,
            params: {
                page: 1,
                perPage: 10
            },
            infiniteId: +new Date(),
            filters: [
                {
                    status: 1,
                    info: this.$t('deposits.inProgress')
                },
                {
                    status: 1,
                    info: this.$t('deposits.feePaidByPassenger')
                },
                {
                    status: 0,
                    info: this.$t('deposits.notPaidByPassenger')
                }
            ]
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            carrierDeposits: 'content/carrierDeposits'
        }),

        windowWidth() {
            return window.innerWidth;
        },

        filterPaid() {
            const { filters } = this;
            let arr = [];
            const { deposit: deposits } = this.carrierDeposits;

            if (filters[0].status) {
                const filteredData = deposits.filter(item => {
                    return item.status === null && item.paid === null;
                });

                arr = [...arr, ...filteredData];
            }

            if (filters[1].status) {
                //zapłata od pasazera
            
                const filteredData = deposits.filter(item => {
                    return item.status === 'COMPLETED' || item.status === 'COMPLETED_PASS_CREDIT';
                });

                arr = [...arr, ...filteredData];
            }

            if (filters[2].status) {
                //brak zapłaty od pasazera
                const filteredData = deposits.filter(item => {
                    return item.status === 'CANCELED' && item.paid === null;
                });

                arr = [...arr, ...filteredData];
            }

            return arr;
        },

        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },

        wallet() {
            const carrerDepositsWallet = this.carrierDeposits.points / 10;
            return carrerDepositsWallet.toFixed(2);
        }
    },

    methods: {
        ...mapMutations({
            clear: 'content/CLEAR_DEPOSIT'
        }),

        ...mapActions({
            getCarrier: 'auth/validate',
            getDeposit: 'content/getDeposit'
        }),

        setFilter(index) {
            const { status } = this.filters[index];
            this.filters[index].status = !status;
        },

        payOffModall() {
            this.showPayOffModal = !this.showPayOffModal;
        },

        infiniteHandler($state) {
            setTimeout(async () => {
                if (this.pageCount > this.params.page && this.pageCount <= 10) {
                    this.params.page += 1;
                    await this.featch();
                    $state.loaded();
                } else {
                    $state.complete();
                }
            }, 500);
        },

        async featch() {
            const params = this.params;
            const { total_count: totalCount } = await this.getDeposit({
                params
            });

            this.pageCount = Math.ceil(totalCount / params.perPage);
            this.isMounted = true;
        },

        changeType() {
            this.params.page = 1;
            this.infiniteId += 1;
        }
    },

    beforeDestroy() {
        this.clear();
        this.isMounted = false;
    },

    async mounted() {
        this.clear();
        await this.featch();
    }
};
</script>
