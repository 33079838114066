<template>
    <div>
        <swipe-list
            v-if="data.length"
            ref="list"
            class="card mt16 mb20"
            :items="data"
            transition-key="id"
        >
            <template slot-scope="{ item }">
                <div @click="itemClick(item)" class="deposit-card-wrapper">
                    <div class="one-row">
                        <div style="center-deposit-price">
                            <div
                                v-bind:class="{
                                    'card-price-green':
                                        item.status === 'COMPLETED' ||
                                        item.status === 'COMPLETED_PASS_CREDIT',
                                    'card-price-red':
                                        item.status === null ||
                                        item.status !== 'COMPLETED' ||
                                        item.status !== 'COMPLETED_PASS_CREDIT'
                                }"
                            >
                                {{ item.price }} {{ $t('global.currency') }}
                            </div>
                        </div>
                        <div>
                            <div class="card-from-to text-deposit-default">
                                <div style="display: flex;">
                                    <div v-if="item.request">
                                        <div>{{ item.request.from }}</div>
                                        <div>{{ item.request.to }}</div>
                                    </div>
                                    <div v-else>{{ item.description }}</div>
                                    <img
                                        v-if="item.request"
                                        class="mobile-deposit-icon"
                                        style="margin-left: 6px; height:28px; margin-top:2px"
                                        src="@assets/images/icon-route.svg"
                                    />
                                </div>
                                <div
                                    v-if="item.request"
                                    style="margin-right: 12px;"
                                >
                                    {{
                                        $formatDate(
                                            item.request.created_at,
                                            $i18n.locale
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="one-row">
                        <div class="card-details">
                            <Statuses :item="item" />
                        </div>
                        <div class="flex-column-center">
                            <md-button
                                class="card-call-btn"
                                @click="call(item)"
                            >
                                <img
                                    :src="
                                        require('@assets/images/icon-call.svg')
                                    "
                                    alt="kredyt"
                                    title="kredyt"
                                />
                                <div class="text uppercase">{{ $t('global.call') }}</div>
                            </md-button>
                        </div>
                    </div>
                </div>
            </template>

            <template slot="left" slot-scope="{ item }">
                <div
                    v-if="item.request"
                    class="swipeout-action swipeout-action-big deposit-action-panel-left"
                >
                    <div @click="itemClick(item)">
                        <img :src="require('@assets/images/icon_search.svg')" />
                        {{ $t('deposits.details') }}
                    </div>
                </div>
            </template>
        </swipe-list>
    </div>
</template>

<script>
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import Statuses from './statuses';

export default {
    components: {
        SwipeOut,
        SwipeList,
        Statuses
    },

    props: {
        data: {
            type: Array,
            default: []
        }
    },

    methods: {
        itemClick(e) {
            if (e.request_uuid) {
                this.$router.push(`/app/${e.request_uuid}`);
            }
        },

        call(item) {
            const { phone } = item;
            location.href = 'tel:' + phone;
        }
    }
};
</script>
