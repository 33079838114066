<template>
    <div>
        <div class="card-icons">
            <div v-for="(element, index) in status" :key="index">
                <span v-if="element" class="pointer">
                    <img
                        :src="
                            require(`@assets/images/${iconsStatuses[index].iconFirst}`)
                        "
                        alt="info"
                    />

                    <md-tooltip md-direction="bottom">
                        <span ng-bind-html="msg | unsafeSpecial">
                            {{ $t('deposits.sent') }}
                        </span>
                    </md-tooltip>
                </span>
                <span v-if="element" class="pointer">
                    <img
                        :src="
                            require(`@assets/images/${iconsStatuses[index].iconSecond}`)
                        "
                        alt="info"
                    />

                    <md-tooltip md-direction="bottom">
                        <span ng-bind-html="msg | unsafeSpecial">
                            {{ $t('deposits.depositPaidByPassenger') }}
                        </span>
                    </md-tooltip>
                </span>
                <span v-if="element" class="pointer">
                    <img
                        :src="
                            require(`@assets/images/${iconsStatuses[index].iconThird}`)
                        "
                        alt="info"
                    />

                    <md-tooltip md-direction="bottom">
                        <span ng-bind-html="msg | unsafeSpecial">
                            {{ $t('deposits.depositNotPaidByPassenger') }}
                        </span>
                    </md-tooltip>
                </span>
            </div>

            <div
                v-if="item.status === null && item.paid === null"
                class="one-row"
            >
                <div class="card-payment-status uppercase">
                    {{ $t('deposits.inProgress') }}
                </div>
            </div>
            <div
                v-if="
                    item.status === 'COMPLETED' ||
                        item.status === 'COMPLETED_PASS_CREDIT'
                "
                class="one-row"
            >
                <div class="card-payment-status uppercase">
                    {{ $t('deposits.depositPaidByPassenger') }}
                </div>
                <div class="card-data-time">
                    {{
                        item.paid_at
                            ? $formatDate(
                                  item.paid_at,
                                  $i18n.locale,
                                  'DD.MM.YYYY HH:mm'
                              )
                            : ''
                    }}
                </div>
            </div>
            <div
                v-if="item.status === 'CANCELED' && item.paid === null"
                class="one-row"
            >
                <div class="card-payment-status uppercase">
                    {{ $t('deposits.depositNotPaidByPassenger') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        item: {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            iconsStatuses: [
                {
                    iconFirst: 'icon_tick_green.svg',
                    iconSecond: 'icon_tick_gray.svg',
                    iconThird: 'icon_tick_gray.svg'
                },
                {
                    iconFirst: 'icon_tick_green.svg',
                    iconSecond: 'icon_tick_green.svg',
                    iconThird: 'icon_tick_gray.svg'
                },
                {
                    iconFirst: 'icon_tick_red.svg',
                    iconSecond: 'con_ex_mark.svg',
                    iconThird: 'icon_tick_gray.svg'
                },
                {
                    iconFirst: 'icon_tick_green.svg',
                    iconSecond: 'icon_tick_green.svg',
                    iconThird: 'icon_tick_green.svg'
                }
            ]
        };
    },

    computed: {
        status() {
            const item = this.item;

            if (item.status === null && item.paid === null) {
                return [1, 0, 0, 0];
            }

            if (
                item.status === 'COMPLETED' ||
                item.status === 'COMPLETED_PASS_CREDIT'
            ) {
                return [0, 1, 0, 0];
            }

            if (item.status === 'CANCELED' && item.paid === null) {
                return [0, 0, 1, 0];
            }

            if (item.paid) {
                return [0, 0, 0, 1];
            }
        }
    },

    methods: {}
};
</script>
