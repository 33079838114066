<template>
    <md-dialog :md-fullscreen="false" :md-active="show">
        <div style="position:relative;">
            <md-button @click="close" class="closeX">
                X
            </md-button>
        </div>

        <div v-if="afterTransfer" class="text-center mt16 ml16 mr16 mb16">
            <img
                v-if="!transferFailed"
                :src="require('@assets/images/icon_success_tick.svg')"
                alt="kredyt"
                title="kredyt"
            />

            <img
                v-else
                :src="require('@assets/images/error.svg')"
                alt="kredyt"
                title="kredyt"
            />
            <div
                class="uppercase"
                :class="{
                    'text-red': transferFailed,
                    'text-green': !transferFailed
                }"
                style="margin-left:0;"
            >
                {{ $t('modals.payOffDeposit') }}
            </div>
        </div>

        <div v-else class="text-center mt16 ml16 mr16 mb16">
            <img
                :src="require('@assets/images/icon_wallet_black.svg')"
                alt="kredyt"
                title="kredyt"
            />
            <div class="text-black uppercase">
                {{ $t('modals.payOffDeposit') }}
            </div>
        </div>

        <div v-if="afterTransfer" class="text-center mt20">
            {{ message }}
        </div>
        <div v-else class="text-center">
            {{ $t('modals.howMuchDoYouWantPayOutDepositMessage') }}
        </div>
        <div v-if="!afterTransfer" class="flex-box mt20 mb20">
            <div class="modal-amount">
                {{ amountToPLN }} {{ $t('global.currency') }} / {{ amount
                }}{{ $t('modals.shortcutPoints') }}
            </div>

            <vue-material-slider v-if="!(points > 50)" disabled />
            <vue-material-slider
                v-else
                :min="50"
                :max="points"
                v-model="amount"
            />
        </div>
        <div v-if="afterTransfer" class="right modal-buttons mt20">
            <md-button class="modal-to-points" @click="close">
                {{ $t('modals.close') }}
            </md-button>
        </div>
        <div v-else class="one-row modal-buttons mt20">
            <md-button
                class="modal-to-bank-account"
                @click="transferToBankAccount"
            >
                {{ $t('modals.onBankAccount') }}
            </md-button>
            <md-button class="modal-to-points" @click="transferToFloteaAccount">
                {{ $t('modals.onPoints') }}
            </md-button>
        </div>
    </md-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        points: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            amount: 0,
            afterTransfer: false,
            transferFailed: false,
            message: '',
            minBankAccountPrice: 500,
            minPrice: 5
        };
    },

    watch: {
        show() {
            this.amount = this.points;
            this.transferFailed = false;
            this.afterTransfer = false;
        }
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        }),

        amountToPLN() {
            const amount = this.amount / 10;
            return amount.toFixed(2);
        }
    },

    methods: {
        ...mapActions({
            payOutDeposit: 'payments/payOutDeposit'
        }),

        async transferToBankAccount() {
            this.afterTransfer = true;
            const amount = this.amount / 10;

            if (amount > this.minBankAccountPrice) {
                this.message = this.$t('modals.paymentToAccountMessage');

                const params = {
                    carrierUUID: this.currentUser.carrierUUID,
                    amount: amount.toFixed(2),
                    advancePayment: null
                };

                await this.payOutDeposit(params);
            } else {
                this.message = `${this.$t(
                    'modals.payoutToBankAccountMadeFrom'
                )} ${this.minBankAccountPrice} 
                    ${this.$t('global.currency')}`;
                this.transferFailed = true;
            }
        },

        async transferToFloteaAccount() {
            this.afterTransfer = true;
            const amount = this.amount / 10;

            if (amount < this.minPrice) {
                this.transferFailed = true;
                this.message = `${this.$t('modals.payoutToPointsMadeFrom')} ${
                    this.minPrice
                }
                    ${this.$t('global.currency')}`;
            } else {
                this.message = this.$t('modals.paymentToFloteaAccountMessage');

                const params = {
                    carrierUUID: this.currentUser.carrierUUID,
                    amount: amount.toFixed(2),
                    advancePayment: amount.toFixed(2)
                };

                await this.payOutDeposit(params);
            }
        },

        close() {
            this.afterTransfer = false;
            this.$emit('close');
        }
    }
};
</script>
