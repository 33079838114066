<template>
    <div v-if="data" class="pb16">
        <md-card
            v-for="item in data"
            :key="item.id"
            class="mt16"
            md-theme="default-card"
        >
            <div @click="itemClick(item)" class="padding-default pointer">
                <div class="one-row">
                    <div class="one-row">
                        <div
                            v-if="item.request"
                            class="icon"
                            style="margin-top:5px; margin-right:6px"
                        >
                            <img src="@assets/images/icon-route.svg" />
                        </div>
                        <div v-if="item.request" class="route">
                            <div>{{ item.request.from }}</div>
                            <div>{{ item.request.to }}</div>
                        </div>
                        <div v-else>{{ item.description }}</div>
                    </div>
                    <div
                        class="right"
                        v-bind:class="{
                            'card-price-green': item.status === 'COMPLETED' || item.status === 'COMPLETED_PASS_CREDIT',
                            'card-price-red':
                                item.status === null ||
                                item.status !== 'COMPLETED' || item.status !== 'COMPLETED_PASS_CREDIT'
                        }"
                    >
                        {{ item.price }} {{ $t('global.currency') }}
                    </div>
                </div>
                <div class="one-row">
                    <div class="card-details">
                        <Statuses :item="item" />
                    </div>
                    <md-button class="card-call-btn" @click="call(item)">
                        <img
                            :src="require('@assets/images/icon-call.svg')"
                            alt="kredyt"
                            title="kredyt"
                        />
                        <div class="text uppercase">{{ $t('global.call') }}</div>
                    </md-button>
                </div>
            </div>
        </md-card>
    </div>
</template>

<script>
import Statuses from './statuses';

export default {
    components: {
        Statuses
    },

    props: {
        data: {
            type: Array,
            default: []
        }
    },

    methods: {
        itemClick(e) {
           
            if (e.request_uuid) {
                this.$router.push(`/app/${e.request_uuid}`);
            }
        },

        call(item) {
            const { phone } = item;
            location.href = 'tel:' + phone;
        }
    }
};
</script>
